import { configureStore } from '@reduxjs/toolkit';

// Import reducers from slices
import uiReducer from './states/ui/ui.state';
import adCreationReducer from './states/adform/ad-form.state';
import networkDataReducer from './states/network-data.state';
import authReducer from './states/auth.state';
import datesReducer from './states/dates/dates.state';
import fileManagerReducer from './states/filemanager/filemanager.state';
import billingReducer from './states/billing/billing.state';
import ninjaApi from './apis';
import './apis/crm.api';
import './apis/dashboard.api';
import './apis/network-accounts/facebook-pages.api';
import './apis/network-accounts/network-accounts.api';
import './apis/network-accounts/socket-events';
import './apis/reporting.api';
import './apis/user.api';
import './apis/workspace/permissions.api';
import './apis/workspace/workspace-setup.api';
import './apis/workspace/workspace.api';
import './apis/sales.api';
import './apis/adset-inner-reporting.api';
import './apis/rules.api';
import './apis/domains.api';
import './apis/country.api';
import './apis/conversion-rules.api';
import './apis/utils.api';
import './apis/emailService.api';
import './apis/cron-update-time.api';
import './states/filemanager/filemanager.api';
import './states/ad_campaign/ad_campaign.api';
import './states/ad_adset/ad_adset.api';
import './states/ad_ad/ad_ad.api';
import './apis/ad-form-draft.api';
import './apis/workspace-access.api';
import './apis/apps/index';
import 'modules/facebook-pixel/api/pixels.api';
import 'modules/conversions/api/conversions.api';
import 'modules/campaigns/api/campaigns.api';
import 'modules/adsets/api/adsets.api';
import 'modules/billing/api';
import 'modules/PostbacksModule/api/postbacks.api';
import 'modules/UrlModule/api/urls.api';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    auth: authReducer,
    adForm: adCreationReducer,
    networkData: networkDataReducer,
    dates: datesReducer,
    filemanager: fileManagerReducer,
    billing: billingReducer,
    /* Api Reducers */
    [ninjaApi.reducerPath]: ninjaApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ninjaApi.middleware),
});
