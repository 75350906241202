import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import Ninja from '@ninja';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import ConnectedShopifyStores from 'modules/apps/apps/ShopifyApp/tables/ConnectedShopifyStores';
import useQueryParams from 'hooks/useQueryParams';

const ShopifyAppDrawer = (props) => {
  const { setUrlParams } = useQueryParams();
  const [success, setSuccess] = useState(Boolean(Ninja.getUrlParam('success', false)));

  return (
    <AutomaticDrawer size='large' title='Shopify' {...props}>
      <div className='flex flex-col gap-4'>
        <div className='py-4'>
          <Button type='primary' onClick={() => setUrlParams({ connect: 'shopify' })}>
            Connect New Store
          </Button>
        </div>

        {success && (
          <div className='w-full'>
            <Alert type='success' message='Your store successfylly connected' />
          </div>
        )}

        <div>
          <ConnectedShopifyStores />
        </div>
      </div>
    </AutomaticDrawer>
  );
};

ShopifyAppDrawer.requiredParams = {
  app: (app) => 'shopify' === app,
};

export default ShopifyAppDrawer;
