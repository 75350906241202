import { Switch } from 'antd';
import { snackbar } from 'components/notifications/notifications';
import React from 'react';
import { useChangeNetworkAccountStatusMutation } from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountStatusSwitch = (props) => {
  const { size = 'small', active, ...rest } = props;

  /** @todo refactor when account is passed */
  const isActive = props.row?.account?.active ?? props.row?.active;
  const id = props.row?.account?.id ?? props.row?.id;

  const [changeNetworkAccountStatusQuery, { isLoading }] = useChangeNetworkAccountStatusMutation();

  const onChange = (active) => {
    return changeNetworkAccountStatusQuery({
      id: id,
      active: Boolean(active),
    })
      .unwrap()
      .then(() => {
        snackbar.success('Successfully changed status');
      })
      .catch(() => {
        snackbar.error('Error changing status');
      });
  };

  return (
    <Switch size={size} checked={isActive} loading={isLoading} onChange={onChange} {...rest} />
  );
};

export default NetworkAccountStatusSwitch;
