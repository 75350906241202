import { Modal, Skeleton, Switch, Typography } from 'antd';
import PlanCard from 'components/PlanCard';
import React, { useEffect, useState } from 'react';
import useWorkspaceBilling from 'store/redux/hooks/useWorkspaceBilling';
import { useListBillingPlansQuery } from 'store/redux/states/billing/billing.api';

const ChoosePlanModal = (props) => {
  const { ...rest } = props;
  const { data: plans = [] } = useListBillingPlansQuery();
  const [yearly, setYearly] = useState(false);
  const { plan_code, is_active, isYearly, isLoading: isBillingDataLoading } = useWorkspaceBilling();

  useEffect(() => {
    setYearly(isYearly);
  }, [isYearly]);

  return (
    <Modal
      footer={false}
      title='Choose Your Plan'
      width='80%'
      closable={is_active}
      maskClosable={is_active}
      keyboard={false}
      getContainer={() => {
        return document.querySelector('#page-content');
      }}
      {...rest}
    >
      <Skeleton loading={isBillingDataLoading} active>
        <div className='flex-col'>
          <div className='flex flex-col justify-center items-center gap-2 w-full py-4 '>
            {!is_active ? (
              <span className='text-2xl text-orange font-bold'>
                All Plans Come With 7 Days Free Trial
              </span>
            ) : null}
          </div>
          <div className='flex justify-center items-center gap-2 w-full py-4 '>
            <Switch checked={yearly} onChange={(checked) => setYearly(checked)} />
            <Typography.Text strong>Pay Yearly</Typography.Text>
          </div>
          <div className='flex flex-center flex-wrap gap-4'>
            {plans.map((plan) => {
              return (
                <div key={plan.code} className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4'>
                  <PlanCard
                    isCurrent={plan.code === plan_code && yearly}
                    plan={plan}
                    yearly={yearly}
                    update={is_active}
                    button_text='Choose'
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Skeleton>
    </Modal>
  );
};

export default ChoosePlanModal;
